
import { defineComponent, inject } from 'vue';
import { GlobalData } from '@/models/GlobalData.model';

export default defineComponent({
  setup() {
    return {
      store: inject<GlobalData>('store')
    }
  },
  methods: {
    deleteUser() {
      
    }
  }
});
