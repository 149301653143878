import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-field" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.store.profile.shelter_options as RVOptions), (option, index) => {
      return (_openBlock(), _createElementBlock("div", null, [
        _withDirectives(_createElementVNode("select", {
          class: "input-style",
          "onUpdate:modelValue": ($event: any) => ((_ctx.store.profile.shelter_options[index].type) = $event)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.campground.cabin_types, (type) => {
            return (_openBlock(), _createElementBlock("option", { value: type }, _toDisplayString(type), 9, _hoisted_3))
          }), 256))
        ], 8, _hoisted_2), [
          [_vModelSelect, _ctx.store.profile.shelter_options[index].type]
        ])
      ]))
    }), 256))
  ]))
}