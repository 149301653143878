import axios from 'axios';
import store from '../store';

const API_URL = process.env.VUE_APP_API_URL;

async function getRequest(endpoint: string, query?: any): Promise<any> {
    try {
        let url = `${API_URL}/${endpoint}`;

        if (query) {
            url += `?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}`;
        }
    
        return (await axios.get(url, {headers: getAuthHeader()})).data;
    } catch (e: any) {
        console.error(e);
        if (e.response.status === 400 && e.response?.data) {
            return e.response.data;
        } else {
            return { error: 'An error has occured.' };
        }
    }
}

async function postRequest(endpoint: string, data?: any) {
    try {
        const url = `${API_URL}/${endpoint}`;

        return (await axios.post(url, data,  {headers: getAuthHeader()})).data;
    } catch (e: any) {
        console.error(e);
        if (e.response.status === 400 && e.response?.data) {
            return e.response.data;
        } else {
            return { error: 'An error has occured.' };
        }
    }
}

async function deleteRequest(endpoint: string) {
    try {
        const url = `${API_URL}/${endpoint}`;

        return (await axios.delete(url, {headers: getAuthHeader()})).data;
    } catch (e: any) {
        console.error(e);
        if (e.response.status === 400 && e.response?.data) {
            return e.response.data;
        } else {
            return { error: 'An error has occured.' };
        }
    }
}

export {
    getRequest,
    postRequest,
    deleteRequest
};

function getAuthHeader() {
    return store.user?.token ? { 'Authorization': `Bearer ${store.user.token}` } : {};
}