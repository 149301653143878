
import { watchEffect, defineComponent } from 'vue';

export default defineComponent({
    name: 'FrequencySelector',
    props: {
        label: {
            type: String,
            required: true
        },
        unit: {
            type: String,
            default: null
        },
        value: {
            type: Number,
            default: null
        }
    },
    emits: ['update:unit', 'update:value'],
    data() {
        return {
            showMore: false
        };
    },
    methods: {
        changes(event: any) {
            watchEffect(() => {
                switch (event.target.value) {
                case "once":
                    this.showMore = false;
                    this.$emit('update:unit', 'once');
                     this.$emit('update:value', 1);
                    break;
                case "every":
                    this.showMore = true;
                    this.$emit('update:unit', 'day');
                    this.$emit('update:value', 1);
                    break;
                }
            });
        }
    }
});
