
import FrequencySelector from '@/components/FrequencySelector.vue';
import { watchEffect, defineComponent } from 'vue';

export default defineComponent({
  name: 'ChargeConfigForm',
  components: {
    FrequencySelector
  },
  props: {
    class: String
  },
  data() {
    return {
      charge: {
        name: '',
        price: null,
        applicationRules: {
          weekdays: {
            type: '',
            days: {
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            }
          },
          dateRange: {
            type: '',
            start: null,
            end: null
          }
        },
        per: '', // "tent" | "rv" | "guest" 
        applyFrequency: {
          unit: '',
          value: null
        },
        billFrequency: {
          unit: '',
          value: null
        },
        guest: {
          step: null,
          free: null
        }
      }
    };
  },
  methods: {
    chargePerChange(event: any) {
      watchEffect(() => {
        switch (event.target.value) {
          case 'tent':
          case 'rv':
            this.charge.guest.step = null;
            this.charge.guest.free = null;
            break;
          case 'guest':
            this.charge.guest.step = 1;
            this.charge.guest.free = null;
        }
      });
    }
  }
});
