import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_date_picker, {
      timezone: _ctx.store.campground.timezone,
      "min-date": _ctx.minDate,
      "is-range": "",
      modelValue: _ctx.dateRange,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
      "model-config": _ctx.modelConfig,
      "disabled-dates": _ctx.disabledDates,
      "onUpdate:fromPage": _ctx.pageChange
    }, null, 8, ["timezone", "min-date", "modelValue", "model-config", "disabled-dates", "onUpdate:fromPage"])
  ]))
}