
import { defineComponent } from 'vue';
import ChargeConfigForm from '@/components/ChargeConfigForm.vue'

export default defineComponent({
  name: 'Home',
  components: {
    ChargeConfigForm
  },
  data() {
    return {
      charges: 0
    };
  }
});
