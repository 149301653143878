import store from '@/store';
import { CampingProfile, ShelterType } from "@/models/CampingProfile.modal";

function setCurrentProfile(data: CampingProfile) {
    store.profile = data;
}

function updateCampingProfile(data: Partial<CampingProfile>) {
    store.profile = {...store.profile, ...data};
}
 
function clearCurrentProfile() {
    store.profile = null;
}

function shelterTypeHasOptions(type: ShelterType): boolean {
    switch (type) {
        case ShelterType.RV:
            return true;
        case ShelterType.Cabin:
            return !!store.campground?.cabin_types;
        default:
            return false;
    }
}

export {
    setCurrentProfile,
    updateCampingProfile,
    clearCurrentProfile,
    shelterTypeHasOptions
};