
import { defineComponent } from "vue";

import store from "@/store";
import { GuestAge, guestAgeDisplay } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        return {
            store,
            GuestAge,
            guestAgeDisplay
        };
    }
});
