import store from '@/store';
import { DateTime } from 'luxon';
import { getRequest } from '../api.service';

let validationTimeout = null;

async function validateReservation(): Promise<null> {
    if (validationTimeout) {
        clearTimeout(validationTimeout);
    }

    await (new Promise((resolve) => {
        validationTimeout = setTimeout(() => getRequest(
            'reservation/validate?'+
            `campground_id=${store.campground.id}`+
            `start=${store.dateRange?.start || ''}&`+
            `end=${store.dateRange?.end || ''}&`+
            `guest_count=${store.profile?.guest_count || ''}&`+
            `guest_ages=${encodeURIComponent(store.profile?.guest_ages.join(','))}&`+
            `shelter_count=${store.profile?.shelter_count}&`+
            `shelter_type=${store.profile?.shelter_type || ''}`
        ).then(response => {
            console.log(response);
            store.reservationValid = response.valid;
            store.errors = response.errors || [];
            store.warnings = response.warnings || [];
            validationTimeout = null;
            resolve(null);
        }), 500);
    }));

    return null;
}

async function getBlockedDates(start: Date = store.dateRange.start as Date, end: Date = store.dateRange.end as Date): Promise<Date[]> {
    const result = await getRequest(
        'reservation/blocked?'+
        `campground_id=${store.campground.id}`+
        `start=${start.toISOString()}&`+
        `end=${end.toISOString()}&`+
        `guest_count=${store.profile?.guest_count || ''}&`+
        `guest_ages=${encodeURIComponent(store.profile?.guest_ages.join(','))}&`+
        `shelter_count=${store.profile?.shelter_count}&`+
        `shelter_type=${store.profile?.shelter_type || ''}&`+
        `shelter_options=${store.profile?.shelter_options ? encodeURIComponent(JSON.stringify(store.profile?.shelter_options)) : ''}`
    );

    return result.blocked;
}

async function getBlockedDatesMonth(month: number, year: number): Promise<Date[]> {
    return getBlockedDates(
        DateTime.fromObject({month, year}, {zone: store.campground.timezone}).startOf('month').toJSDate(),
        DateTime.fromObject({month, year}, {zone: store.campground.timezone}).endOf('month').toJSDate()
    );
}

export {
    validateReservation,
    getBlockedDatesMonth,
    getBlockedDates
};