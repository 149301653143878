
import { defineComponent } from 'vue';

import userService from '@/services/reserve/user.service';
import store from '@/store';

enum Mode {
    Login,
    Register
}

export default defineComponent({
    setup() {
        return {
            store
        };
    },
    data() {
        return {
            mode: Mode.Login,
            id: '',
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            error: '',
            Mode
        };
    },
    methods: {
            async login() {
                try {
                const user: any = await userService.loginUser(
                    this.$data.id,
                    this.$data.password
                );

                if (user.error) {
                    this.setError(user.error);
                    this.$emit('failure');
                    return;
                }

                this.id = '';
                this.password = '';
                this.error = '';
            } catch (e) {
                console.error(e);
                this.setError('An error has occured.');
                this.$emit('failure');
                return;
            }

            this.$emit('success');
        },
        async register() {
            try {
                if (this.$data.password !== this.$data.confirmPassword) {
                    this.setError('The passwords entered do not match', false);
                    return;
                }

                const user: any = await userService.registerUser(
                    this.$data.name,
                    this.$data.email,
                    this.$data.password
                );

                if (user.error) {
                    this.setError(user.error);
                    this.$emit('failure');
                    return;
                }
            } catch (e) {
                console.error(e);
                this.setError('An error has occured.');
                this.$emit('failure');
                return;
            }

            this.$emit('success');
        },
        setError(error: string, clearFields: boolean = true) {
            this.$data.error = error;

            if (clearFields) {
                this.$data.id = '';
                this.$data.name = '';
                this.$data.email = '';
                this.$data.password = '';
                this.$data.confirmPassword = '';
            }
        }
    }
});
