enum GuestAge {
    Infant = '0-2',
    Child = '3-17',
    Adult = '18+',
    Senior = '65+'
}

const guestAgeDisplay = {
    [GuestAge.Infant]: 'Infant (0 - 2)',
    [GuestAge.Child]: 'Child (3 - 17)',
    [GuestAge.Adult]: 'Adult (18+)',
    [GuestAge.Senior]: 'Senior (65+)'
};

enum ShelterType {
    None = 'none',
    Tent = 'tent',
    Cabin = 'cabin',
    RV = 'rv'
}

const shelterTypeDisplay = {
    [ShelterType.None]: 'None',
    [ShelterType.Tent]: 'Tent',
    [ShelterType.Cabin]: 'Cabin',
    [ShelterType.RV]: 'RV'
};

enum RVType {
    MotorHomeClassA = 'motorhome_class_a',
    MotorHomeClassB = 'motorhome_class_b',
    MotorHomeClassC = 'motorhome_class_c',
    TravelTrailer   = 'travel_trailer',
    FifthWheel      = 'fifth_wheel',
    PopupTrailer    = 'popup_trailer',
    TruckCamper     = 'truck_camper'
}

const rvTypeDisplay = {
    [RVType.MotorHomeClassA]:   'Class A Motor Home',
    [RVType.MotorHomeClassB]:   'Class B Motor Home',
    [RVType.MotorHomeClassC]:   'Class C Motor Home',
    [RVType.TravelTrailer]:     'Travel Trailer',
    [RVType.FifthWheel]:        'Fifth Wheel',
    [RVType.PopupTrailer]:      'Popup Trailer',
    [RVType.TruckCamper]:       'Truck Camper',
};

enum RVElectricalHookup {
    Amp30 = 'amp_30',
    Amp50 = 'amp_50'
}

const rvElectricalHookupDisplay = {
    [RVElectricalHookup.Amp30]: '30 AMP',
    [RVElectricalHookup.Amp50]: '50 AMP'
};

class CabinOptions {
    type: string;

    constructor() {
        this.type = null;
    }
}

class RVOptions {
    type: RVType;
    toy_hauler: boolean;
    length_ft: number;
    electrical_hookup: RVElectricalHookup;
    sewer: boolean;
}

type ShelterOptions = CabinOptions | RVOptions;

class CampingProfile {
    _id?: string;

    name?: string;

    guest_count: number;

    guest_ages: GuestAge[];

    shelter_type: ShelterType;

    shelter_count: number;  // TODO: Decide if this is even needed. Maybe not?

    shelter_options?: ShelterOptions[];

    constructor() {
        this.guest_count = 1;
        this.guest_ages = [GuestAge.Adult];
        this.shelter_type = ShelterType.None;
        this.shelter_count = 1;
        this.shelter_options = [null];
    }
}

function getNewShelterOptions(type: ShelterType) {
    switch (type) {
        case ShelterType.Cabin:
            return new CabinOptions();
        case ShelterType.RV:
            return new RVOptions();
        default:
            return null;
    }
}

export {
    GuestAge,
    guestAgeDisplay,

    ShelterType,
    CabinOptions,
    RVOptions,
    RVType,
    RVElectricalHookup,
    ShelterOptions,
    CampingProfile,

    shelterTypeDisplay,
    rvTypeDisplay,
    rvElectricalHookupDisplay,
    getNewShelterOptions
};