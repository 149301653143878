
import { defineComponent, inject } from 'vue';

import userService from '../services/reserve/user.service';
import { GlobalData } from '@/models/GlobalData.model';

export default defineComponent({
    setup() {
        return {
            store:  inject<GlobalData>('store')
        };
    },
    data() {
        return {
            username: '',
            email: '',
            error: ''
        };
    },
    methods: {
        async deleteUser() {
            if (this.$data.username !== this.store.user.username ||
                this.$data.email !== this.store.user.email_address) {
                this.setError('Username and email address are incorrect');
                return;
            }

            const response: any = await userService.deleteUser();
            
            this.setError(response.error || '');
            if (response.error) {
                return;
            }

            this.$router.push('/');
        },
        setError(error: string) {
            this.$data.error = error;
        }
    }
});
