
import { defineComponent, ref, watch} from "vue";

import store from "@/store";
import { GuestAge } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        let cached = store.profile.guest_count;
        
        watch(store.profile, (profile) => {
            if (profile.guest_count === cached) {
                return;
            }

            cached = profile.guest_count;

            if (!store.profile.guest_ages.length) {
                store.profile.guest_ages = [GuestAge.Adult];
            } else if (store.profile.guest_ages.length > cached) {
                store.profile.guest_ages = store.profile.guest_ages.slice(0, cached);
            } else if (store.profile.guest_ages.length < cached) {
                const diff = cached - store.profile.guest_ages.length;
                const newAges = [...store.profile.guest_ages];
                for (let i = 0; i < diff; i++) {
                    newAges.push(null);
                }

                store.profile.guest_ages = newAges;
            }
        });

        return {
            store
        };
    }
})

