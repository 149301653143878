import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-field" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.profile.guest_ages.length, (index) => {
      return _withDirectives((_openBlock(), _createElementBlock("select", {
        class: "input-style",
        "onUpdate:modelValue": ($event: any) => ((_ctx.store.profile.guest_ages[index-1]) = $event)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.GuestAge), (age) => {
          return (_openBlock(), _createElementBlock("option", { value: age }, _toDisplayString(_ctx.guestAgeDisplay[age]), 9, _hoisted_3))
        }), 256))
      ], 8, _hoisted_2)), [
        [_vModelSelect, _ctx.store.profile.guest_ages[index-1]]
      ])
    }), 256))
  ]))
}