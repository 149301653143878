<template>
  <div class="not-found">
    <h1>
      404
    </h1>
    <p>
      Sorry, the page you requested does not exist.
    </p>
  </div>
</template>
