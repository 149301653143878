
import { computed, defineComponent, ref, watch } from "vue";
import {
  clearCurrentProfile,
  setCurrentProfile,
  shelterTypeHasOptions,
} from "@/services/reserve/profile.service";
import {
  clearCurrentCampground,
  getCampground,
  setCurrentCampground,
} from "@/services/reserve/campground.service";
import {
  CampingProfile,
  ShelterType,
  shelterTypeDisplay,
} from "@/models/CampingProfile.modal";
import store, { loggedIn } from "@/store";
import { RouteLocation, useRoute, useRouter } from "vue-router";

import GuestCount from "./components/GuestCount.vue";
import AgeSelect from "./components/AgeSelect.vue";
import ReserveDates from "./components/ReserveDates.vue";
import ShelterTypeSelect from "./components/ShelterTypeSelect.vue";
import ShelterCountSelect from "./components/ShelterCount.vue";
import RVOptionsSelect from "./components/RVOptionsSelect.vue";
import CabinOptionsSelect from "./components/CabinOptionsSelect.vue";
import { validateReservation } from "@/services/reserve/reservation.service";

enum Step {
  Start = -1,
  PickProfile,
  PickDates,
  GuestCount,
  GuestAges,
  ShelterType,
  ShelterCount,
  ShelterOptions,
  Review,
  End,
}

function startRoute(site_id: string) {
  return { name: "Reserve", params: { site_id, step: Step.PickDates } };
}

export default defineComponent({
  components: {
    GuestCount,
    AgeSelect,
    ReserveDates,
    ShelterTypeSelect,
    ShelterCountSelect,
    RVOptionsSelect,
    CabinOptionsSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const nextButton = ref(null);

    const shelterTypes = ref(store.campground.shelter_types_available);

    const showPrevious = computed(() => {
      const step = +route.params.step;
      return loggedIn.value ? !!step : step > 1;
    });

    const showNext = computed(() => {
      const step = +route.params.step;

      if (step + 1 === Step.End) {
        return false;
      }

      switch (step) {
        case Step.PickDates:
          return !!store.dateRange?.start && !!store.dateRange?.end;
        case Step.GuestAges:
          for (let age of store.profile.guest_ages) {
            if (!age) {
              return false;
            }
          }
          break;
        case Step.ShelterType:
          return store.profile.shelter_type !== ShelterType.None;
          break;
      }

      return true;
    });

    const previousStep = computed(() => {
      let prev = +route.params.step - 1;
      if (
        (prev === Step.PickProfile && !loggedIn.value) ||
        (prev === Step.ShelterOptions &&
          !shelterTypeHasOptions(store.profile?.shelter_type))
      ) {
        prev--;
      }
      return { name: "Reserve", params: { ...route.params, step: prev } };
    });
    const nextStep = computed(() => {
      let next = +route.params.step + 1;
      if (
        (next === Step.PickProfile && !loggedIn.value) ||
        (next === Step.ShelterOptions &&
          !shelterTypeHasOptions(store.profile?.shelter_type))
      ) {
        next++;
      }
      return { name: "Reserve", params: { ...route.params, step: next } };
    });

    const showStep = (step: Step) => step === +route.params.step;

    watch(loggedIn, () => {
      router.push(startRoute(route.params.site_id as string));
    });

    watch(store.profile, (profile) => {
      validateReservation();
    })

    return {
      store,
      showStep,
      showPrevious,
      showNext,
      previousStep,
      nextStep,
      Step,

      shelterTypes,

      nextButton,
      keyPress(event: KeyboardEvent) {
        if (event.key === "Enter") {
          nextButton.value.$el.click();
        }
      },
      shelterTypeDisplay,
      ShelterType,
    };
  },
  beforeRouteEnter: prepRoute,
});

async function resetCampground(siteId: string) {
  clearCurrentCampground();
  let campground;
  try {
    campground = await getCampground(siteId);
  } catch (e) {
    // TODO: handle other errors besides 404
    return "/NotFound";
  }

  if (!campground) {
    return "/NotFound";
  }

  setCurrentCampground(campground);
}

async function resetDateRange() {
  store.dateRange = { start: null, end: null };
}

async function resetProfile() {
  clearCurrentProfile();
  setCurrentProfile(new CampingProfile());
}

async function prepRoute(to: RouteLocation) {
  console.log("prepRoute", to);
  const step = +to.params.step;
  if (isNaN(step) || step < Step.Start || step > Step.End) {
    return "/NotFound";
  }

  if (step === Step.Start || !store.profile || !store.campground) {
    console.log("here");
    resetCampground(to.params.site_id as string);
    resetDateRange();
    resetProfile();
    return startRoute(to.params.site_id as string);
  }
}
