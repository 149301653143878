import VueNumberComponent from '@chenfengyuan/vue-number-input';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Plugins
import VCalendar from 'v-calendar';
import vfmPlugin from 'vue-final-modal';

// // Store
// import store from './store'

// Global Components
import AppBar from './components/AppBar.vue'

createApp(App)
    .use(router)
    .use(vfmPlugin)
    .use(VCalendar, {})
    .component('appBar', AppBar)
    .component(VueNumberComponent.name, VueNumberComponent)
    .mount('#app')
