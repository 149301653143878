
import { defineComponent, watch } from "vue";

import store from "@/store";
import { getNewShelterOptions } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        let cached = store.profile.shelter_count;

        watch(store.profile, (profile) => {
            if (profile.shelter_count === cached) {
                return;
            }

            cached = profile.shelter_count;

            if (!store.profile.shelter_options?.length) {
                 store.profile.shelter_options = [null];
            } else if (store.profile.shelter_options.length > cached) {
                 store.profile.shelter_options = store.profile.shelter_options.slice(0, cached);
            } else if (store.profile.shelter_options.length < cached) {
                const diff = cached - store.profile.shelter_options.length;
                const newOptions = [...store.profile.shelter_options];
                for (let i = 0; i < diff; i++) {
                    newOptions.push(getNewShelterOptions(profile.shelter_type));
                }
                store.profile.shelter_options = newOptions;
            }
        });

        return {
            store
        };
    }
});
