
import { defineComponent } from "vue";

import store from "@/store";
import { CabinOptions, ShelterType, shelterTypeDisplay } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        return {
            store,
            CabinOptions,
            ShelterType,
            shelterTypeDisplay
        };
    }
});
