import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Reserve from '../views/reserve/Reserve.vue';
import Dashboard from '../views/Dashboard.vue';
import ChargeConfig from '@/views/ChargeConfig.vue';
import DeleteUser from '@/views/DeleteUser.vue';
import NotFound from '@/views/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:site_id/reserve',
    redirect: to => ({name: 'Reserve', params: {site_id: to.params.site_id, step: -1}})
  },
  {
    path: '/:site_id/reserve/:step',
    name: 'Reserve',
    component: Reserve,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/charge-config-example',
    name: 'chargeConfig',
    component: ChargeConfig
  },
  {
    path: '/delete-user',
    name: 'deleteUser',
    component: DeleteUser
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
