import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChargeConfigForm = _resolveComponent("ChargeConfigForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.charges++;})
    }, "Add"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charges, (i) => {
      return (_openBlock(), _createBlock(_component_ChargeConfigForm, { key: i }))
    }), 128))
  ]))
}