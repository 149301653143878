import { User } from './User.model';
import { Campground } from './Campground.model';
import { CampingProfile } from './CampingProfile.modal';
import { reactive, Ref, ref } from 'vue';

export class GlobalData {
    user: User;
    campground: Campground; // TODO: Rename to Campground
    profile: CampingProfile;
    dateRange: {start: Date, end: Date};
    reservationValid: Ref<boolean>;
    errors: string[];
    warnings: string[];
}