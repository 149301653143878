import { User } from '../../models/User.model';
import * as apiService from '../api.service';
import store, {loggedIn} from '../../store';

const SESSION_STORAGE_CAMPY_USER = 'campy_user_data';

async function loginUser(id: string, password: string): Promise<User | {error: string}> {
    const user = await apiService.getRequest('user/login', {id, password});

    if (user.error) {
        return user;
    }
    
    loggedIn.value = true;
    store.user = user;
    sessionStorage.setItem(SESSION_STORAGE_CAMPY_USER, JSON.stringify(user));
    
    return user;
}

function logoutUser() {
    sessionStorage.removeItem(SESSION_STORAGE_CAMPY_USER);
    store.user = null;
    loggedIn.value = false;
}

function setUserFromSession() {
    const user = sessionStorage.getItem(SESSION_STORAGE_CAMPY_USER);
    if (user) {
        store.user = JSON.parse(user);
        loggedIn.value = true;
    }
}

async function registerUser(username: string, email_address: string, password: string): Promise<User | {error: string}> {
    const user = await apiService.postRequest('user/register', {username, email_address, password});

    if (user.error) {
        return user;
    }
    store.user = user;
    sessionStorage.setItem(SESSION_STORAGE_CAMPY_USER, JSON.stringify(user));
    loggedIn.value = true;

    return user;
}

async function deleteUser() {
    const response = await apiService.deleteRequest(`user/${store.user.email_address}`);

    if (!response.error) {
        logoutUser();
    }

    return response;
}

export default {
    loginUser,
    logoutUser,
    setUserFromSession,
    registerUser,
    deleteUser
};