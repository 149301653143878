import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cb64638"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "nav" }
const _hoisted_2 = {
  key: 0,
  class: "user-greeting right-side"
}
const _hoisted_3 = { class: "modal-container modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginUser = _resolveComponent("LoginUser")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.store?.user?.username)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Hi, " + _toDisplayString(_ctx.store.user.username), 1))
      : _createCommentVNode("", true),
    (!_ctx.loggedIn)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "right-side login-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLogin = true))
        }, " Login / Register "))
      : (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLogout = true))
        }, " Logout ")),
    _createVNode(_component_vue_final_modal, {
      modelValue: _ctx.showLogin,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showLogin) = $event)),
      classes: "modal-container",
      "content-class": "modal-content"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LoginUser, {
          onSuccess: _cache[2] || (_cache[2] = ($event: any) => {_ctx.showLogin = false;})
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_vue_final_modal, {
      modelValue: _ctx.showLogout,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showLogout) = $event)),
      classes: "modal-container"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(" Are you sure you want to log out " + _toDisplayString(_ctx.store?.user?.username) + "? ", 1),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.logout()))
          }, "Yes"),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showLogout = false))
          }, "No")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}