import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_number_input = _resolveComponent("vue-number-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_number_input, {
      controls: "",
      inline: "",
      modelValue: _ctx.store.profile.guest_count,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.profile.guest_count) = $event)),
      min: 1
    }, null, 8, ["modelValue"])
  ]))
}