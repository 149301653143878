
import { DateTime } from "luxon";
import { computed, defineComponent, ref, watch } from "vue";
import store from "@/store";

import { getBlockedDatesMonth } from "@/services/reserve/reservation.service";

export default defineComponent({
  mounted() {
    const now = DateTime.local().setZone(store.campground.timezone);
    getBlockedDatesMonth(now.month, now.year).then((dates) => {
      console.log(dates);
      this.disabledDates = dates;
    });
  },
  setup() {
    const dateRange = ref(store.dateRange);

    watch(dateRange, (val: any) => {
      store.dateRange.start = val.start;
      store.dateRange.end = val.end;
    });

    const disabledDates = ref([]);

    const modelConfig = ref({
      start: {
        timeAdjust: store.campground.check_in_time,
      },
      end: {
        timeAdjust: store.campground.check_out_time,
      },
    });

    const minDate = computed(() => {
      return DateTime.local()
        .setZone(store.campground?.timezone)
        .plus({ hours: store.campground?.reservation_lead_time || 0 })
        .toJSDate();
    });

    return {
      store,
      dateRange,
      modelConfig,
      minDate,
      disabledDates,
      pageChange(page) {
        getBlockedDatesMonth(page.month, page.year)
            .then(dates => disabledDates.value = dates);
      }
    };
  },
});
