
import userService from "@/services/reserve/user.service";
import { defineComponent, ref } from "vue";
import LoginUser from "@/views/reserve/LoginUser.vue";
import store, { loggedIn } from "@/store";

export default defineComponent({
    setup() {
        const showLogin = ref(false);
        const showLogout = ref(false);

        function logout() {
          userService.logoutUser();
          showLogout.value = false;
        } 

        return {
            store,

            showLogin,
            showLogout,
            loggedIn,
            logout
        };
    },
    components: { LoginUser }
});
