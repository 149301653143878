
import { defineComponent, watch } from "vue";

import store from "@/store";
import { getNewShelterOptions, ShelterType, shelterTypeDisplay } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        let cached = store.profile.shelter_type;

        watch(store.profile, (profile) => {
            if (profile.shelter_type === cached) {
                return;
            }

            cached = profile.shelter_type;

            for (let i = 0; i < store.profile.shelter_options.length; i++) {
                store.profile.shelter_options[i] = getNewShelterOptions(profile.shelter_type);
            }
        });

        return {
            store,
            ShelterType,
            shelterTypeDisplay
        };
    }
});
