import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-field" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      class: "input-style",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.profile.shelter_type) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.ShelterType), (type) => {
        return (_openBlock(), _createElementBlock("option", { value: type }, _toDisplayString(_ctx.shelterTypeDisplay[type]), 9, _hoisted_2))
      }), 256))
    ], 512), [
      [_vModelSelect, _ctx.store.profile.shelter_type]
    ])
  ]))
}