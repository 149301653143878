
import { defineComponent } from "vue";

import store from "@/store";
import { RVElectricalHookup, rvElectricalHookupDisplay, RVOptions, RVType, rvTypeDisplay } from "@/models/CampingProfile.modal";

export default defineComponent({
    setup() {
        return {
            store,   
            RVType,
            rvTypeDisplay,
            RVElectricalHookup,
            rvElectricalHookupDisplay
        };
    }
});
