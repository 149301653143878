import { ShelterType } from "@/models/CampingProfile.modal";
import { Campground } from "@/models/Campground.model";
import store from "@/store";

async function getCampground(siteId: string): Promise<Campground> {
    // TODO: Make real API call
    return {
        id: siteId,
        name: 'Holly Lake Campground',
        shelter_types_available: [
            ShelterType.Tent,
            ShelterType.RV,
            ShelterType.Cabin
        ],
        cabin_types: [
            'Small',
            'Large'
        ],
        reservation_lead_time: 24,
        check_in_time: '15:00:00',
        check_out_time: '12:00:00',
        timezone: 'America/New_York'
    };
}

function setCurrentCampground(campground: Campground) {
    store.campground = campground;
}

function clearCurrentCampground() {
    store.campground = null;
}

export {
    getCampground,
    setCurrentCampground,
    clearCurrentCampground
};